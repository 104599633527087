import React from 'react';
import { NavLink } from "react-router-dom";
import { motion, useInView } from 'framer-motion';
import { useRef } from 'react';

// Import the local images
import campusAmbassadorImage from '../assets/2.png'; // Update path
import departmentalRepImage from '../assets/rep.png'; // Update path
const Initiatives = () => {
  // Create a reference for the section to track if it's in view
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true, threshold: 0.2 }); // Triggers when 20% of the element is in view

  return (
    <section id="initiatives" className="w-full py-12 md:py-20 bg-red-300 flex justify-center items-center">
      <div className="container px-4 md:px-6 flex flex-col">
        {/* Initiatives Title and Text */}
        <motion.div
          ref={ref}
          initial={{ opacity: 0, y: 50 }} // Initial position: hidden and moved down
          animate={isInView ? { opacity: 1, y: 0 } : {}} // Animate when in view
          transition={{ duration: 0.8, ease: "easeOut" }} // Smooth transition
          className="flex flex-col items-center justify-center space-y-4 text-center"
        >
          <div className="space-y-2">
            <h2 className="text-3xl font-bold tracking-tighter sm:text-4xl">Our Initiatives</h2>
            <p className="max-w-xl text-muted-foreground md:text-xl">
              Explore our diverse range of initiatives designed to empower aspiring entrepreneurs.
            </p>
          </div>
        </motion.div>

        {/* Initiatives Cards */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-8">
          

          {/* Departmental Representative */}
          <div
            className="relative bg-cover bg-center rounded-xl p-6 shadow-lg h-[35vh] hover:shadow-xl transition-shadow duration-300 ease-in-out bg-white group"
            style={{ backgroundImage: `url(${departmentalRepImage})` }} // Using local image
          >
            {/* Overlay on hover */}
            <div className="absolute inset-0 bg-black bg-opacity-70 opacity-0 group-hover:opacity-100 transition-opacity duration-300 rounded-xl flex flex-col justify-center items-center">
              <h3 className="text-xl font-extrabold text-white">Department Representative</h3>
              <p className="mt-2 text-white text-center">
                The Department Representative program allows students from various academic departments to serve as
                liaisons between their departments and the Entrepreneurship Cell. They will help coordinate events,
                workshops, and initiatives tailored to their department's needs.
              </p>
              <NavLink to={"/deprep"}>
                <button className="mt-4 border-2 border-white text-white rounded-md px-4 py-2 hover:bg-white hover:text-black transition">
                  Learn More
                </button>
              </NavLink>
            </div>
          </div>
          {/* Campus Ambassador Program */}
          <div
            className="relative bg-cover bg-center rounded-xl p-6 shadow-lg h-[35vh] hover:shadow-xl transition-shadow duration-300 ease-in-out bg-white group"
            style={{ backgroundImage: `url(${campusAmbassadorImage})` }} // Using local image
          >
            {/* Overlay on hover */}
            <div className="absolute inset-0 bg-black bg-opacity-70 opacity-0 group-hover:opacity-100 transition-opacity duration-300 rounded-xl flex flex-col justify-center items-center">
              <h3 className="text-xl font-extrabold text-white">Join Us</h3>
              <p className="mt-2 text-white text-center">
                Joining our team provides students with the opportunity to be the face of the Entrepreneurship
                Cell and promote entrepreneurship on campus. The team will receive training, networking
                opportunities, and the chance to lead initiatives.
              </p>
              <NavLink to={"/joinus"}>
                <button className="mt-4 border-2 border-white text-white rounded-md px-4 py-2 hover:bg-white hover:text-black transition">
                  Fill Now
                </button>
              </NavLink>
            </div>
          </div>
        </div>
        
      </div>
    </section>
  );
};

export default Initiatives;
