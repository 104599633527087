import React from 'react';
import { useSpring, animated } from '@react-spring/web';
import { FaUsers, FaHandsHelping, FaEye, FaChalkboardTeacher } from 'react-icons/fa';

const Statistic = ({ icon: Icon, label, number }) => {
  const props = useSpring({
    from: { number: 0 },
    number,
    delay: 200,
    config: { duration: 3000, tension: 50, friction: 10 }, // Slower animation
  });

  return (
    <div id='stats' style={{ textAlign: 'center', margin: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Icon size={40} color="red" style={{ marginBottom: '10px' }} />
      <animated.div style={{ fontSize: '2em', fontWeight: 'bold' }}>
        {props.number.to((n) => n.toFixed(0))}
      </animated.div>
      <div style={{ fontSize: '1.2em', color: '#555' }}>{label}</div>
    </div>
  );
};

const Statistics = () => {
  const data = [
    { label: 'Partners', number: 120, icon: FaUsers },
    { label: 'Initiatives', number: 2, icon: FaHandsHelping },
    { label: 'Site Visitors', number: 15000, icon: FaEye },
    { label: 'Workshops', number: 40, icon: FaChalkboardTeacher },
  ];

  return (
    <div className='flex max-sm:flex-col gap-5 items-center justify-center w-screen'>
      {data.map((stat) => (
        <Statistic key={stat.label} label={stat.label} number={stat.number} icon={stat.icon} />
      ))}
    </div>
  );
};

export default Statistics;
