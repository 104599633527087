import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Amb from './pages/amb';
import Dep from './pages/dep';



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
  <Routes>
    <Route element={App()} path='/'/>
    <Route element={Amb()} path='/joinus'/>
    <Route element={Dep()} path='/deprep'/>

  </Routes>
  </BrowserRouter>
);
