import { motion, useScroll, useTransform } from "framer-motion";
import { NavLink } from "react-router-dom";
import { useRef } from "react";

export default function Hero() {
    // Handler function for the partner button


  // Create a ref for the specific element (Hero section)
  const ref = useRef(null);

  // Capture scroll progress of the specific element
  const { scrollYProgress } = useScroll({
    target: ref, // Ref targeting the element
    offset: ["start end", "end start"], // Trigger the scroll progress when the top or bottom of the element starts/end
  });

  // Map scroll progress to a zoom scale
  const scale = useTransform(scrollYProgress, [0, 1], [0.5,1.5]);



  return (
    <motion.div
      ref={ref} // Attach ref to the element you want to track
      id="home"
      className="w-screen h-screen bg-red-600 flex flex-col items-center sm:justify-center max-sm:pt-[15vh]"
       // Apply zoom on scroll
    >
        <motion.div style={{ scale }}>
            <h1 className="font-bold text-[3vw] max-sm:text-[5vw] text-white" >
                Empowering Entrepreneurs at
            </h1>
        </motion.div>
        <motion.div style={{ scale}}>
            <h1 className="font-extrabold text-center uppercase text-[4vw] max-sm:text-[14vw] text-white animate-pulse">
                Chandigarh University
            </h1>
        </motion.div>
        <motion.div style={{ scale }}>
            <p className="w-[50vw] text-center text-white max-sm:text-[3vw] max-sm:w-[80vw] text-[1.5vw] mt-[3vh]">
                The Entrepreneurship Cell at Chandigarh University is dedicated to
                fostering a vibrant entrepreneurial ecosystem, providing students with
                the resources, mentorship, and opportunities to turn their ideas into
                successful ventures.
            </p>
        </motion.div>
      
     
      <div className="flex gap-4 max-sm:flex-col mt-[10vh]">
        <NavLink to={"/joinus"}>
          <button className="p-4 border-white border-2 rounded-md text-white hover:bg-white hover:text-black duration-300">
            Join The Team
          </button>
        </NavLink>
        
      </div>
    </motion.div>
  );
}
