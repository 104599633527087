import React, { useState } from 'react';
import axios from 'axios';
import { PiLightbulbBold } from 'react-icons/pi';
import { NavLink, useNavigate } from 'react-router-dom';

const DepRep = () => {
  const navigate=useNavigate();
  const [formData, setFormData] = useState({
    name: '',
    department: '',
    uid: '',
    phoneNumber: '',
    email: '',
  });

  const [file, setFile] = useState(null); // State to handle file input
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const handleChange = (e) => {
    console.log(e.target.value);
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]); // Handle file input
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post('https://ecell-9poq.onrender.com/api/postDepRep', formData, {

      });
      if (response.status === 201) {
        setSuccess('Form submitted successfully!');
        navigate('/deprep');
       }
    } catch (err) {
      console.log(err);
      setError('There was an error submitting the form.');
      setSuccess('');
    }
  };

  return (
    <div className="flex flex-col min-h-screen">
      <header className="px-4 lg:px-6 h-14 flex items-center bg-white shadow">
        <NavLink to="#" className="flex items-center justify-center">
          <PiLightbulbBold color="red" size={30} />
          <div className="text-[1.3vw] max-sm:text-[5vw] font-bold">Entrepreneurship Cell</div>
        </NavLink>
      </header>
      <main className="flex-1">
        <div className="md:flex-1 justify-center items-center flex flex-col w-full bg-red-500">
          <h1 className="text-3xl font-bold tracking-tighter sm:text-5xl xl:text-6xl text-white rounded-tl-xl p-4">
            Become a Department Representative
          </h1>
          <p className="text-white md:text-xl p-4 rounded-bl-xl">
            Join our dynamic team and be the face of your department on campus. Gain valuable experience, build your network, and make a real impact.
          </p>
        </div>
        <section className="w-full py-12 md:py-24 lg:py-32 bg-red-500">
          <div className="container mx-auto px-4 md:px-6">
            <div className="flex flex-col md:flex-row justify-between space-y-8 md:space-y-0 md:space-x-8">
              <div className="md:flex-1">
                <div className="flex flex-col space-y-6">
                  <div className="bg-gray-200 rounded-md p-6">
                    <BriefcaseIcon className="h-8 w-8 text-red-500 mb-2" />
                    <h3 className="text-lg font-semibold">Develop Leadership Skills</h3>
                    <p className="text-gray-700">
                      Gain hands-on experience in event planning, public speaking, and team management as a Department Representative.
                    </p>
                  </div>
                  <div className="bg-gray-200 rounded-md p-6">
                    <UsersIcon className="h-8 w-8 text-red-500 mb-2" />
                    <h3 className="text-lg font-semibold">Expand Your Network</h3>
                    <p className="text-gray-700">
                      Connect with a diverse group of students, faculty, and industry professionals, opening up new opportunities.
                    </p>
                  </div>
                  <div className="bg-gray-200 rounded-md p-6">
                    <MegaphoneIcon className="h-8 w-8 text-red-500 mb-2" />
                    <h3 className="text-lg font-semibold">Make a Difference</h3>
                    <p className="text-gray-700">
                      Be the voice and face of our department, helping to promote our initiatives and reach new audiences.
                    </p>
                  </div>
                  <div className="bg-gray-200 rounded-md p-6">
                    <DollarSignIcon className="h-8 w-8 text-red-500 mb-2" />
                    <h3 className="text-lg font-semibold">Earn Certfications</h3>
                    <p className="text-gray-700">
                      Access to exclusive workshops, webinars, or certifications relevant to their interests and career goals.
                    </p>
                  </div>
                </div>
              </div>
              <div className="md:flex-1 bg-gray-200 rounded-md p-6">
                <div className="text-center uppercase font-extrabold text-[2vw] mb-[10vh] max-sm:text-[10vw] text-red-500">
                  Representative Form
                </div>
                {error && <div className="text-red-500">{error}</div>}
                {success && <div className="text-green-500">{success}</div>}
                <form className="space-y-4" onSubmit={handleSubmit}>
                  <input
                    type="text"
                    name="name"
                    placeholder="Name"
                    value={formData.name}
                    onChange={handleChange}
                    className="w-full p-3 rounded-md border border-gray-300"
                  />
                  <input
                    type="text"
                    name="department"
                    placeholder="Department"
                    value={formData.department}
                    onChange={handleChange}
                    className="w-full p-3 rounded-md border border-gray-300"
                  />
                  <input
                    type="text"
                    name="uid"
                    placeholder="UID"
                    value={formData.uid}
                    onChange={handleChange}
                    className="w-full p-3 rounded-md border border-gray-300"
                  />
                  <input
                    type="text"
                    name="phoneNumber"
                    placeholder="Phone Number"
                    value={formData.phoneNumber}
                    onChange={handleChange}
                    className="w-full p-3 rounded-md border border-gray-300"
                  />
                  <input
                    type="email"
                    name="email"
                    placeholder="Email"
                    value={formData.email}
                    onChange={handleChange}
                    className="w-full p-3 rounded-md border border-gray-300"
                  />
                  {/* <input
                    type="file"
                    name="file"
                    onChange={handleFileChange}
                    className="w-full p-3 rounded-md border border-gray-300"
                  /> */}
                  <button type="submit" className="w-full rounded-md bg-red-500 p-3 text-white">
                    Submit Representative Form
                  </button>
                </form>
              </div>
            </div>
          </div>
        </section>
      </main>
      <footer className="flex flex-col sm:flex-row py-6 w-full items-center px-4 md:px-6 border-t bg-white">
        <p className="text-xs text-gray-600">&copy; 2024 Campus Ambassador Program. All rights reserved.</p>
        <nav className="sm:ml-auto flex gap-4 sm:gap-6">
          <NavLink to="#" className="text-xs hover:underline underline-offset-4">
            Terms of Service
          </NavLink>
          <NavLink to="#" className="text-xs hover:underline underline-offset-4">
            Privacy
          </NavLink>
        </nav>
      </footer>
    </div>
  );
};

const BriefcaseIcon = (props) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M16 20V4a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v16" />
    <rect width="20" height="14" x="2" y="6" rx="2" />
  </svg>
);

const DollarSignIcon = (props) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <line x1="12" x2="12" y1="2" y2="22" />
    <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6" />
  </svg>
);

const MegaphoneIcon = (props) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="m3 11 18-5v12L3 14v-3z" />
    <path d="M11.6 16.8a3 3 0 1 1-5.8-1.6" />
  </svg>
);



const UsersIcon = (props) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
    <circle cx="9" cy="7" r="4" />
    <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
    <path d="M16 3.13a4 4 0 0 1 0 7.75" />
  </svg>
);

export default DepRep;
