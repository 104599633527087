import React, { useState } from 'react';
import axios from 'axios';
import { PiLightbulbBold } from 'react-icons/pi';

const Footer = () => {
    const [phNumber, setEmail] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('http://localhost:4000/api/postSponsor', { phNumber });
            console.log('Form submitted successfully:', response.data);
            if (response.status === 201) {
                window.location.reload();
            }
        } catch (error) {
            console.error('Error submitting form:', error);
            // Handle the error, maybe show an error message to the user
        }
    };

    return (
        <section className="py-10 bg-gray-50 sm:pt-16 lg:pt-24">
            <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
                <div className="grid grid-cols-2 md:col-span-3 lg:grid-cols-6 gap-y-16 gap-x-12">
                    <div className="col-span-2 md:col-span-3 lg:col-span-2 lg:pr-8">
                        <div className="max-sm:w-screen flex flex-row gap-2 max-sm:justify-between mt-[4vh]">
                            <div><PiLightbulbBold color="red" size={30} /></div>
                            <div className="text-[1.3vw] max-sm:text-[5vw] max-sm:mr-[10vw] font-bold">Entrepreneurship <br></br>Cell</div>
                        </div>
                        <ul className="flex items-center space-x-3 mt-9">
                            <li>
                                <a href="/home" title="" className="flex items-center justify-center text-white transition-all duration-200 bg-gray-800 rounded-full w-7 h-7 hover:bg-red-600 focus:bg-red-600">
                                    {/* Social Media Icons */}
                                </a>
                            </li>
                            {/* More social media links... */}
                        </ul>
                    </div>

                    <div>
                        <p className="text-sm font-semibold tracking-widest text-gray-400 uppercase">E-CELL</p>
                        <ul className="mt-6 space-y-4">
                            <li><a href="/home" className="flex text-base text-black transition-all duration-200 hover:text-red-600 focus:text-red-600">About</a></li>
                            <li><a href="/home" className="flex text-base text-black transition-all duration-200 hover:text-red-600 focus:text-red-600">Features</a></li>
                            <li><a href="/home" className="flex text-base text-black transition-all duration-200 hover:text-red-600 focus:text-red-600">Works</a></li>
                            <li><a href="/home" className="flex text-base text-black transition-all duration-200 hover:text-red-600 focus:text-red-600">Workshop</a></li>
                        </ul>
                    </div>

                    <div>
                        <p className="text-sm font-semibold tracking-widest text-gray-400 uppercase">Help</p>
                        <ul className="mt-6 space-y-4">
                            <li><a href="/home" className="flex text-base text-black transition-all duration-200 hover:text-red-600 focus:text-red-600">Contact Us</a></li>
                            <li><a href="/home" className="flex text-base text-black transition-all duration-200 hover:text-red-600 focus:text-red-600">Sponsorship Related</a></li>
                            <li><a href="/home" className="flex text-base text-black transition-all duration-200 hover:text-red-600 focus:text-red-600">Core Team</a></li>
                        </ul>
                    </div>

                    <div className="col-span-2 md:col-span-1 lg:col-span-2 lg:pl-8">
                        <p className="text-sm font-semibold tracking-widest text-gray-400">Interested in becoming a sponsor?</p>
                        <form onSubmit={handleSubmit} className="mt-6">
                            <div>
                                <input
                                    type="text"
                                    name="email"
                                    id="email"
                                    placeholder="Enter your Contact"
                                    value={phNumber}
                                    onChange={(e) => setEmail(e.target.value)}
                                    className="block w-full p-4 text-black placeholder-gray-500 transition-all duration-200 bg-white border border-gray-200 rounded-md focus:outline-none focus:border-red-600 caret-red-600"
                                />
                            </div>
                            <button
                                type="submit"
                                className="inline-flex items-center justify-center px-6 py-4 mt-3 font-semibold text-white transition-all duration-200 bg-red-600 rounded-md hover:bg-white focus:bg-white hover:text-black focus:text-black"
                            >
                                Get a Call
                            </button>
                        </form>
                    </div>
                </div>

                <hr className="mt-16 mb-10 border-gray-200" />

                <p className="text-sm text-center text-gray-600">© Copyright 2024, All Rights Reserved by E-Cell Chandigarh University</p>
            </div>
        </section>
    );
}

export default Footer;
