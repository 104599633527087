import './App.css';
import Carousel from './components/Events';
import Footer from './components/Footer';
import Hero from './components/Hero';
import Initiatives from './components/Initiatives';
import Landing from './components/Landing';
import Navbar from './components/Navbar';
import Partnership from './components/Partnership';
import Statistics from './components/Statistics';

function App() {
  return (
    <div className="App overflow-x-hidden">
      <Landing/>
      <div className='top-0 fixed bg-white z-10'><Navbar/></div>
      <Hero/>
      <Statistics/>
      <div id='initiatives'><Initiatives/></div>
      {/* <Workshops/> */}
      <div id='events'><Carousel/></div>
      <div id='partnership'></div><Partnership/>
      <Footer/>
    </div>
  );
}

export default App;
