import { MdClose, MdOutlineMenu } from "react-icons/md";
import { useState } from "react";
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { NavLink } from "react-router-dom";
import { FaBars } from "react-icons/fa";


export default function Navbar(){
    const [expanded,setExpanded]=useState(false);
    function expandHandler(){
        setExpanded(!expanded);
    }

    return (
        <div>
            <div className="md:flex hidden justify-center items-center absolute w-screen">
            <div className=" mt-[5vh] h-[8vh] flex items-center justify-around px-4 duration-300 bg-black rounded-lg text-white">
            
            <ul className="hidden flex-row font-bold sm:flex items-center">
                <li className="mx-[3vw] duration-300 hover:text-red-600"><AnchorLink href="#home">Home</AnchorLink></li>
                <li className="hover:text-red-600 duration-300"><AnchorLink href="#initiatives">Initiatives</AnchorLink></li>
                <div className="max-sm:w-screen flex flex-row items-center gap-2 max-sm:justify-between mx-[3vw]">
                <NavLink to={"/"}><img src={require('../assets/logo.png')} alt="logo" height={100} width={100}></img></NavLink>
                <div className="hidden max-sm:self-end max-sm:block"><MdOutlineMenu size={30} onClick={expandHandler}/></div>
            </div>
                <li className="hover:text-red-600 duration-300"><AnchorLink href="#events">Events</AnchorLink></li>
                <li className="mx-[3vw] hover:text-red-600 duration-300"><AnchorLink href="#partnership">Partnership</AnchorLink></li>
            </ul>
            
        </div>
        </div>
            <div className="max-sm:flex hidden w-screen bg-black fixed h-[10vh] justify-between items-center p-4">
                <NavLink to={"/"}><img src={require('../assets/logo.png')} alt="logo" height={100} width={100}></img></NavLink>
                <FaBars size={30} color="white" onClick={expandHandler}/>
            </div>
            <div className={expanded?"w-screen h-[40vh] flex flex-row items-center justify-around bg-black absolute duration-300":"h-0 overflow-hidden duration-300"}>
                <div className="text-white">
                    <ul className="flex flex-col gap-3 text-[7vw]">
                        <li className="duration-300 hover:text-red-600"><AnchorLink href="#home">Home</AnchorLink></li>
                        <li className="hover:text-red-600 duration-300"><AnchorLink href="#initiatives">Initiatives</AnchorLink></li>
                        <li className="hover:text-red-600 duration-300"><AnchorLink href="#events">Events</AnchorLink></li>
                        <li className=" hover:text-red-600 duration-300"><AnchorLink href="#partnership">Partnership</AnchorLink></li>
                    </ul>
                </div>
                <div className="text-white">
                    <MdClose size={50} color="white" onClick={expandHandler}/>
                </div>
            </div>

        </div>
           )
}