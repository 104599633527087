import React from 'react';
import { NavLink } from 'react-router-dom';

export default function Partnership() {
    
    return (
        <section id="partnership" className="w-full py-12 md:py-20 bg-gray-200 flex justify-center items-center">
            {/* <div className="container px-4 md:px-6">
                <div className="flex flex-col items-center justify-center space-y-4 text-center">
                    <div className="space-y-2">
                        <h2 className="text-3xl font-bold tracking-tighter sm:text-4xl">Become a Partner</h2>
                        <p className="max-w-xl text-gray-500 md:text-xl">
                            Join our network of partners and contribute to the growth of the entrepreneurial ecosystem at
                            Chandigarh University.
                        </p>
                    </div>
                </div>
                <form onSubmit={handleSubmit} className="mt-8 max-w-md mx-auto space-y-4">
                    <input
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        placeholder="Name"
                        className="w-full p-2 rounded-md"
                        required
                    />
                    <input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        placeholder="Email"
                        className="w-full p-2 rounded-md"
                        required
                    />
                    <textarea
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                        placeholder="Message"
                        rows={4}
                        className="w-full h-[20vh] p-2 rounded-md"
                        required
                    />
                    <button
                        type="submit"
                        className="w-full rounded-md bg-red-500 p-3 text-white"
                        disabled={isSubmitting}
                    >
                        {isSubmitting ? 'Submitting...' : 'Submit Partnership Form'}
                    </button>
                    {error && <p className="text-red-500 mt-2">{error}</p>}
                    {success && <p className="text-green-500 mt-2">Form submitted successfully!</p>}
                </form>
            </div> */}
            <img src={require('../assets/E-Cell_Banner.png')} className='absolute' alt='banner'></img>
            <NavLink to={"/joinus"}><button className='text-white absolute max-sm:ml-[20vw] md:mt-[40vh] p-5 rounded-xl w-[10vw] text-[1.4vw] max-sm:text-[3vw] max-sm:w-[20vw] max-sm:mt-[-5vh] font-extrabold hover:text-white hover:bg-gray-400 duration-300 bg-red-500'>Join Us</button></NavLink>
        </section>
    );
}
