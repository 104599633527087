import React, { useState } from 'react';
import axios from 'axios';
import { NavLink, useNavigate } from 'react-router-dom';

const CampusAmbassador = () => {
  const navigate = useNavigate();
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [formData,setFormData]=useState({
      name:'',
      email:'',
      contactNumber:'',
      uid:'',
      courseBranch:'',
      year:'',
      interest:'',
      introduceYourself:'',
      fitReason:'',
      joinReason:'',
      feedback:''
  
  });

  const handleChange = (e) => {
    console.log(e.target.value);
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // const handleFileChange = (e) => {
  //   setFile(e.target.files[0]);
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();


    // if (file) formDataToSend.append('file', file);
    console.log(formData) 

    try {
      const response = await axios.post('https://ecell-9poq.onrender.com/api/postAmbassadors', formData);
      // const response = await axios.post('http://localhost:4000/api/postAmbassadors', formDataToSend);

      if (response.status === 201) {
        setSuccess('Form submitted successfully!');
        navigate('/');
      }
    } catch (err) {
      console.error(err);
      setError('There was an error submitting the form.');
      setSuccess('');
    }
  };

  return (
    <div className="flex flex-col min-h-screen bg-gray-100">
      <header className="bg-black text-white p-4 shadow-md flex items-center justify-between">
        <NavLink to="/">
          <img src={require('../assets/logo.png')} alt="logo" className="h-12 w-12" />
        </NavLink>
        <nav className="space-x-4">
          <NavLink to="/" className="hover:underline">Home</NavLink>
          <NavLink to="/about" className="hover:underline">About</NavLink>
          <NavLink to="/contact" className="hover:underline">Contact</NavLink>
        </nav>
      </header>

      <main className="flex-1 py-12 px-4 sm:px-6 lg:px-8">
        <section className="max-w-4xl mx-auto bg-white p-6 rounded-lg shadow-md">
          <h1 className="text-4xl font-bold text-center mb-6 text-red-600">Join Our Core Team</h1>
          <p className="text-lg text-gray-700 mb-6 text-center">
            Join our dynamic team and be the face of our brand on your campus. Gain valuable experience, build
            your network, and make a real impact.
          </p>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
            <FeatureCard icon={<BriefcaseIcon />} title="Gain Experience" description="Develop valuable skills in marketing, event planning, and leadership as a Campus Ambassador." />
            <FeatureCard icon={<UsersIcon />} title="Build Your Network" description="Connect with like-minded students and professionals, expanding your network and opportunities." />
            <FeatureCard icon={<MegaphoneIcon />} title="Make an Impact" description="Be the face of our brand on campus and help us reach new audiences, driving real growth." />
            <FeatureCard icon={<DollarSignIcon />} title="Earn Certifications" description="Access to exclusive workshops, webinars, or certifications relevant to their interests and career goals." />
          </div>

          <div className="bg-gray-50 p-6 rounded-lg shadow-md">
            {error && <div className="text-red-600 mb-4">{error}</div>}
            {success && <div className="text-green-600 mb-4">{success}</div>}
            <form className="space-y-4" onSubmit={handleSubmit}>
              <div className="space-y-2">
                <label className="block font-semibold text-gray-700">Name*</label>
                <input
                  type="text"
                  placeholder="Name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  className="w-full p-3 border border-gray-300 rounded-md"
                  required
                />
              </div>

              <div className="space-y-2">
                <label className="block font-semibold text-gray-700">Email Id*</label>
                <input
                  type="email"
                  placeholder="Email Id"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  className="w-full p-3 border border-gray-300 rounded-md"
                  required
                />
              </div>

              <div className="space-y-2">
                <label className="block font-semibold text-gray-700">Contact Number*</label>
                <input
                  type="text"
                  placeholder="Contact Number"
                  name="contactNumber"
                  value={formData.contactNumber}
                  onChange={handleChange}
                  className="w-full p-3 border border-gray-300 rounded-md"
                  required
                />
              </div>

              <div className="space-y-2">
                <label className="block font-semibold text-gray-700">UID*</label>
                <input
                  type="text"
                  placeholder="UID"
                  name="uid"
                  value={formData.uid}
                  onChange={handleChange}
                  className="w-full p-3 border border-gray-300 rounded-md"
                  required
                />
              </div>

              <div className="space-y-2">
                <label className="block font-semibold text-gray-700">Course/Branch*</label>
                <input
                  type="text"
                  placeholder="Course/Branch"
                  name="courseBranch"
                  value={formData.courseBranch}
                  onChange={handleChange}
                  className="w-full p-3 border border-gray-300 rounded-md"
                  required
                />
              </div>

              <div className="space-y-2">
                <label className="block font-semibold text-gray-700">Year*</label>
                <select
                  value={formData.year}
                  onChange={handleChange}
                  name="year"
                  className="w-full p-3 border border-gray-300 rounded-md"
                  required
                >
                  <option value="">Select Year</option>
                  <option value="1st Year">1st Year</option>
                  <option value="2nd Year">2nd Year</option>
                  <option value="3rd Year">3rd Year</option>
                  <option value="4th Year">4th Year</option>
                </select>
              </div>

              <div className="space-y-2">
                <label className="block font-semibold text-gray-700">Interested in?*</label>
                <select
                  value={formData.interest}
                  onChange={handleChange}
                  name="interest"
                  className="w-full p-3 border border-gray-300 rounded-md"
                  required
                >
                  <option value="">Select Interest</option>
                  <option value="Tech Team">Tech Team</option>
                  <option value="Marketing Team">Marketing Team</option>
                  <option value="Design Team">Design Team</option>
                  <option value="Event Management">Event Management</option>
                  <option value="PR Team">PR Team</option>
                  <option value="Policies Formation & Implementation">Policies Formation & Implementation</option>
                </select>
              </div>

              <div className="space-y-2">
                <label className="block font-semibold text-gray-700">Introduce Yourself*</label>
                <textarea
                  placeholder="Introduce Yourself"
                  value={formData.introduceYourself}
                  onChange={handleChange}
                  name="introduceYourself"
                  className="w-full p-3 border border-gray-300 rounded-md"
                  rows="4"
                  required
                />
              </div>

              <div className="space-y-2">
                <label className="block font-semibold text-gray-700">Why do you think you're the best fit for the team you've applied for?*</label>
                <textarea
                  placeholder="Why do you think you're the best fit?"
                  value={formData.fitReason}
                  onChange={handleChange}
                  name="fitReason"
                  className="w-full p-3 border border-gray-300 rounded-md"
                  rows="4"
                  required
                />
              </div>

              <div className="space-y-2">
                <label className="block font-semibold text-gray-700">Why do you want to join the E-Cell Team?*</label>
                <textarea
                  placeholder="Why do you want to join?"
                  value={formData.joinReason}
                  name="joinReason"
                  onChange={handleChange}
                  className="w-full p-3 border border-gray-300 rounded-md"
                  rows="4"
                  required
                />
              </div>

              <div className="space-y-2">
                <label className="block font-semibold text-gray-700">Any Feedback/Suggestions?</label>
                <textarea
                  placeholder="Feedback/Suggestions"
                  value={formData.feedback}
                  name="feedback"
                  onChange={handleChange}
                  className="w-full p-3 border border-gray-300 rounded-md"
                  rows="4"
                />
              </div>

              {/* <div className="space-y-2">
                <label className="block font-semibold text-gray-700">Upload Resume (optional)</label>
                <input
                  type="file"
                  onChange={handleFileChange}
                  className="w-full border border-gray-300 rounded-md"
                />
              </div> */}

              <button
                type="submit"
                className="w-full bg-red-500 text-white p-3 rounded-md hover:bg-red-600"
              >
                Submit Application
              </button>
            </form>
          </div>
        </section>
      </main>

      <footer className="bg-black text-white py-4 px-6">
        <div className="container mx-auto flex flex-col sm:flex-row justify-between items-center">
          <p className="text-xs">&copy; 2024 Campus Ambassador Program. All rights reserved.</p>
          <nav className="space-x-4">
            <NavLink to="#" className="text-xs hover:underline">Terms of Service</NavLink>
            <NavLink to="#" className="text-xs hover:underline">Privacy</NavLink>
          </nav>
        </div>
      </footer>
    </div>
  );
};

const FeatureCard = ({ icon, title, description }) => (
  <div className="bg-gray-50 p-4 rounded-lg shadow-md flex items-start space-x-4">
    <div className="text-red-600 text-2xl">{icon}</div>
    <div>
      <h3 className="text-lg font-semibold text-gray-800">{title}</h3>
      <p className="text-gray-600">{description}</p>
    </div>
  </div>
);
const BriefcaseIcon = (props) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
    <path d="M16 20V4a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v16" />
    <rect width="20" height="14" x="2" y="6" rx="2" />
  </svg>
);

const DollarSignIcon = (props) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
    <line x1="12" x2="12" y1="2" y2="22" />
    <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6" />
  </svg>
);

const MegaphoneIcon = (props) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
    <path d="m3 11 18-5v12L3 14v-3z" />
    <path d="M11.6 16.8a3 3 0 1 1-5.8-1.6" />
  </svg>
);

const UsersIcon = (props) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
    <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
    <circle cx="9" cy="7" r="4" />
    <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
    <path d="M16 3.13a4 4 0 0 1 0 7.75" />
  </svg>
);

export default CampusAmbassador;
