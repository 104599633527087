import { FaInstagram } from "react-icons/fa";
import { IoLogoLinkedin } from "react-icons/io5";
import { FaFacebook } from "react-icons/fa";
import { FaFacebookMessenger } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { IoLogoYoutube } from "react-icons/io";
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { NavLink } from 'react-router-dom';
import { useState } from 'react';
import { motion } from 'framer-motion';
import { FaArrowRightLong } from "react-icons/fa6";

function Landing() {
  const [instaHover, setInstahover] = useState(false);
  const [linkedinHover, setLinkedHover] = useState(false);
  const [fbHover, setFbHover] = useState(false);
  const [messengerHover, setMessengerHover] = useState(false);
  const [ytHover, setYtHover] = useState(false);
  const [twitterHover, setTwitterHover] = useState(false);

  return (
    <div className="Landing bg-black object-contain flex flex-col overflow-x-hidden">
      <img src={require('../assets/bg.jpg')} className='object-cover h-screen w-screen overflow-x-hidden' alt='bg'></img>
      <motion.div className='bg-[#3f566e] w-full h-screen absolute opacity-60 flex flex-col gap-3 justify-end pb-7 pl-8 overflow-x-hidden'>
        <motion.div animate={{ y: 0 }} transition={{ duration: 0.5 }} initial={{ opacity: 0, y: 100 }} whileInView={{ opacity: 1 }} >
          <NavLink to='/'>
            <FaInstagram 
              onMouseEnter={() => setInstahover(true)} 
              onMouseLeave={() => setInstahover(false)} 
              color={instaHover ? 'black' : 'white'}  
              size={'2vw'} 
              className='max-sm:size-[6vw] duration-300'
            />
          </NavLink>
        </motion.div>
        <motion.div animate={{ y: 0 }} transition={{ duration: 0.5, delay: 0.3 }} initial={{ opacity: 0, y: 100 }} whileInView={{ opacity: 1 }}>
          <NavLink to='/'>
            <IoLogoLinkedin 
              onMouseEnter={() => setLinkedHover(true)} 
              onMouseLeave={() => setLinkedHover(false)} 
              color={linkedinHover ? 'black' : 'white'} 
              size={'2vw'} 
              className='max-sm:size-[6vw] duration-300'
            />
          </NavLink>
        </motion.div>
        <motion.div animate={{ y: 0 }} transition={{ duration: 0.5, delay: 0.6 }} initial={{ opacity: 0, y: 100 }} whileInView={{ opacity: 1 }}>
          <NavLink to='/'>
            <FaFacebookMessenger 
              onMouseEnter={() => setMessengerHover(true)} 
              onMouseLeave={() => setMessengerHover(false)} 
              color={messengerHover ? 'black' : 'white'} 
              size={'2vw'} 
              className='max-sm:size-[6vw] duration-300'
            />
          </NavLink>
        </motion.div>
        <motion.div animate={{ y: 0 }} transition={{ duration: 0.5, delay: 0.9 }} initial={{ opacity: 0, y: 100 }} whileInView={{ opacity: 1 }}>
          <NavLink to='/'>
            <FaFacebook 
              onMouseEnter={() => setFbHover(true)} 
              onMouseLeave={() => setFbHover(false)} 
              color={fbHover ? 'black' : 'white'} 
              size={'2vw'} 
              className='max-sm:size-[6vw] duration-300'
            />
          </NavLink>
        </motion.div>
        <motion.div animate={{ y: 0 }} transition={{ duration: 0.5, delay: 1.2 }} initial={{ opacity: 0, y: 100 }} whileInView={{ opacity: 1 }}>
          <NavLink to='/'>
            <IoLogoYoutube 
              onMouseEnter={() => setYtHover(true)} 
              onMouseLeave={() => setYtHover(false)} 
              color={ytHover ? 'black' : 'white'} 
              size={'2vw'} 
              className='max-sm:size-[6vw] duration-300'
            />
          </NavLink>
        </motion.div>
        <motion.div animate={{ y: 0 }} transition={{ duration: 0.5, delay: 1.5 }} initial={{ opacity: 0, y: 50 }} whileInView={{ opacity: 1 }}>
          <NavLink to='/'>
            <FaTwitter 
              onMouseEnter={() => setTwitterHover(true)} 
              onMouseLeave={() => setTwitterHover(false)} 
              color={twitterHover ? 'black' : 'white'} 
              size={'2vw'} 
              className='max-sm:size-[6vw] duration-300'
            />
          </NavLink>
        </motion.div>
      </motion.div>
      <motion.div animate={{ y: 0 }} transition={{ duration: 1, delay: 0.7 }} initial={{ opacity: 0, y: 100 }} whileInView={{ opacity: 1 }} className='text-[4vw] md:text-[3vw] lg:text-[2vw] absolute select-none font-bold text-white mt-[33vh] max-sm:text-[6vw] max-sm:mt-[25vh] self-center '>Ideate.Innovate.Empower</motion.div>

      <motion.div animate={{ y: 0 }} transition={{ duration: 1, delay: 0.7 }} initial={{ opacity: 0, y: 100 }} whileInView={{ opacity: 1 }} className='text-[10vw] md:text-[8vw] lg:text-[5vw] select-none font-extrabold text-white max-sm:mt-[55vh] mt-[40vh] max-sm:text-[15vw] self-center absolute '>E-CELL</motion.div>

      <motion.div animate={{ y: 0 }} transition={{ duration: 1 }} initial={{ opacity: 0, y: 100 }} whileInView={{ opacity: 1 }} className='w-full absolute select-none text-white uppercase font-extrabold text-center max-sm:mt-[30vh] mt-[50vh] text-[10vw] max-sm:text-[15vw] md:text-[8vw] lg:text-[6vw] overflow-x-hidden flex-wrap text-wrap'>
        <span className='text-red-600 select-none text-wrap'>Chandigarh </span>University
      </motion.div>
      <motion.div animate={{ y: 0 }} transition={{ duration: 1 }} initial={{ opacity: 0, y: 100 }} whileInView={{ opacity: 1 }} className='self-center select-none absolute mt-[70vh]'>
        <AnchorLink href="#stats"><button className='border-2 text-white border-white rounded-md text-[3vw] md:text-[2vw] lg:text-[1vw] hover:bg-gray-400 duration-300 w-[30vw] md:w-[20vw] lg:w-[10vw] h-[10vh]'>Explore Now</button></AnchorLink>
      </motion.div>
        
        <motion.div className='self-end rotate-90 mt-[80vh] w-[20vw] md:w-[15vw] lg:w-[10vw] h-[20vh] bottom-0 text-white flex items-baseline animate-pulse gap-3 absolute'>
          <div className='text-[3vw]  md:text-[2vw] lg:text-[1vw]'>Scroll to Explore</div>
          <FaArrowRightLong size={'5vw'} className='md:size-4vw lg:size-3vw'/>
        </motion.div>  
        
      </div> 
  
  );
}

export default Landing;
