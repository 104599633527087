import React, { useRef, useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css'; 
import 'slick-carousel/slick/slick-theme.css';
import { FaChevronRight, FaChevronLeft } from "react-icons/fa";

const Carousel = () => {
  const sliderRef = useRef(null);
  const [currentSlide, setCurrentSlide] = useState(0);
  const totalSlides = 3; // Adjust this based on the number of slides

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false, // Disable default arrows
    beforeChange: (current, next) => setCurrentSlide(next),
  };

  const handlePrev = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };

  const handleNext = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };

  return (
    <div className="relative w-full">
      <div className='w-screen text-center max-sm:text-[10vw] text-[4vw] uppercase font-extrabold my-[10vh] text-red-500'>
        Upcoming Events
      </div>
      <Slider {...settings} ref={sliderRef}>
        <div className="p-1">
          <div className="card">
            <div className="card-content flex flex-col items-center justify-center p-6">
              <video
                controls
                autoPlay
                muted
                loop
                className="w-full max-w-[600px] h-auto max-sm:w-[80vw] max-sm:h-[50vw] object-cover"
              >
                <source src={require('../assets/video.mp4')} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
              <div className="mt-4 space-y-1">
                <h3 className="text-lg font-extrabold text-center">Rise Summit</h3>
                <p className="text-muted-foreground">April 21 - 22, 2025 | 10:00 AM - 2:00 PM</p>
              </div>
            </div>
          </div>
        </div>
        <div className="p-1">
          <div className="card">
            <div className="card-content flex flex-col items-center justify-center p-6">
              <video
                controls
                autoPlay
                muted
                loop
                className="w-full max-w-[600px] h-auto max-sm:w-[80vw] max-sm:h-[50vw] object-cover"
              >
                <source src={require('../assets/video2.mp4')} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
              <div className="mt-4 space-y-1">
                <h3 className="text-lg font-semibold">Innovate to Elevate</h3>
                <p className="text-muted-foreground">November 02, 2024 | 1:00 PM - 4:30 PM</p>
              </div>
            </div>
          </div>
        </div>
        <div className="p-1">
          <div className="card">
            <div className="card-content flex flex-col items-center justify-center p-6">
              <img
                src={require("../assets/getset.jpg")}
                alt="Event"
                className="w-full max-w-[600px] h-auto rounded-lg object-cover max-sm:w-[80vw] max-sm:h-[50vw]"
              />
              <div className="mt-4 space-y-1">
                <h3 className="text-lg font-semibold">Get Set Pitch</h3>
                <p className="text-muted-foreground">September 20, 2024 | 9:00 AM - 5:00 PM</p>
              </div>
            </div>
          </div>
        </div>
      </Slider>
      {/* Custom Navigation Buttons */}
      <button
        className="hidden lg:block absolute top-1/2 left-0 transform -translate-y-1/2 bg-gray-800 text-white p-2 rounded-full shadow-md hover:bg-gray-600"
        onClick={handlePrev}
      >
        <FaChevronLeft color='white'/>
      </button>
      <button
        className="hidden lg:block absolute top-1/2 right-0 transform -translate-y-1/2 bg-gray-800 text-white p-2 rounded-full shadow-md hover:bg-gray-600"
        onClick={handleNext}
      >
        <FaChevronRight color='white'/>
      </button>
      {/* Slide Indicator */}
      <div className="text-center mt-[5vh]">
        <span className="text-gray-700">Slide {currentSlide + 1} of {totalSlides}</span>
      </div>
    </div>
  );
};

export default Carousel;
